(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("classnames"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "react", "classnames", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["ReactToastify"] = factory(require("prop-types"), require("react"), require("classnames"), require("react-dom"));
	else
		root["ReactToastify"] = factory(root["prop-types"], root["react"], root["classnames"], root["react-dom"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 